<template>
  <div class="container-fluid mt-3">

   <div class="d-flex flex-wrap mb-1 align-items-center justify-content-between">
     <Breadcrumb :current_page="` جميع المستخدمين (${total})` "/>
     <router-link class="ms-2 mb-1 el-button el-button--success el-button--default btn-add"  to="/add-user">
       إضافة مستخدم جديد
     </router-link>
   </div>

    <el-card>
      <el-table id="top-table" :data="users" class="mb-4" stripe style="width: 100%"
                max-height="850" v-loading="loading">
        <el-table-column prop="id" class-name="px-3 first-row" label="#" width="60"/>
        <el-table-column prop="display_name" label="الاسم" width="140"/>
        <el-table-column label="الصلاحية" width="140">
          <template #default="scope">
            {{ scope.row.roles[0]?.description }}
          </template>
        </el-table-column>
        <el-table-column prop="username" label="اسم المستخدم" width="140"/>
        <el-table-column prop="email" label="البريد الإلكتروني" width="210"/>

        <el-table-column v-if="getCurrentUser.type_id === '1' || getCurrentUser.type_id === '2'" label="الأدوات"
                         width="120" class-name="control-btns">
          <template #default="scope">
            <template v-if="getCurrentUser.type_id === '2'">
              <template v-if="scope.row.roles[0]?.id !== 1">
                <el-tooltip class="item" effect="dark" content="تعديل المستخدم" placement="top">
                  <router-link :to="`/edit-user/${scope.row.id}`" class="py-1 px-2 btn btn-outline-primary">
                    <i class="far fa-edit"/>
                  </router-link>
                </el-tooltip>
                <el-popconfirm
                    hide-icon
                    confirmButtonType="danger"
                    cancelButtonType="default"
                    confirmButtonText="نعم"
                    cancelButtonText="لا"
                    iconColor="red"
                    @confirm="confirmDisable(scope.row.id)"
                    :title="`هل أنت متأكد أنك تريد ${scope.row.is_disable ? 'تفعيل' : 'تعطيل'} هذا المستخدم؟`">
                  <template #reference>
                    <el-button :title="`${scope.row.is_disable ? 'تفعيل' : 'تعطيل'} المستخدم`" class="py-1 px-2 btn"
                               :class="`${scope.row.is_disable ? 'btn-outline-secondary' : 'btn-outline-warning'}`">
                      <i class="las la-ban" style="font-size: 1.35em"/>
                    </el-button>
                  </template>
                </el-popconfirm>
              </template>
              <div v-else>
                &nbsp;
              </div>
            </template>
            <template v-else>
              <el-tooltip class="item" effect="dark" content="تعديل المستخدم" placement="top">
                <router-link :to="`/edit-user/${scope.row.id}`" class="py-1 px-2 btn btn-outline-primary">
                <i class="far fa-edit"/>
                </router-link>
              </el-tooltip>
              <el-popconfirm
                  hide-icon
                  confirmButtonType="danger"
                  cancelButtonType="default"
                  confirmButtonText="نعم"
                  cancelButtonText="لا"
                  iconColor="red"
                  @confirm="confirmDisable(scope.row.id)"
                  :title="`هل أنت متأكد أنك تريد ${scope.row.is_disable ? 'تفعيل' : 'تعطيل'} هذا المستخدم؟`">
                <template #reference>
                  <el-button :title="`${scope.row.is_disable ? 'تفعيل' : 'تعطيل'} المستخدم`" class="py-1 px-2 btn"
                             :class="`${scope.row.is_disable ? 'btn-outline-secondary' : 'btn-outline-warning'}`">
                    <i class="las la-ban" style="font-size: 1.35em"/>
                  </el-button>
                </template>
              </el-popconfirm>
            </template>
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="users.length" v-model="page" :records="total" :per-page="per_page" :options="{hideCount:true}"
                  @paginate="getAllUsers"/>
    </el-card>
  </div>

</template>

<script>
import Loader from "@/components/Loader";
import Breadcrumb from "@/components/Breadcrumb";
import {getUsers} from "@/services/users";
import {disableUser} from "../../services/users";
import {useToast} from "vue-toastification";
import {mapGetters} from "vuex";

export default {
  name      : "Users",
  components: {
    Loader,
    Breadcrumb
  },
  data()
  {
    return {
      toast   : useToast(),
      users   : [],
      loading : false,
      total   : 0,
      page    : 1,
      per_page: 1
    }
  },
  mounted()
  {
    this.getAllUsers();
  },
  computed: {
    ...mapGetters(['getCurrentUser'])
  },
  methods : {
    getAllUsers()
    {
      this.loading = true
      getUsers(this.page)
          .then(res => {
            this.loading  = false
            this.total    = res.data.total
            this.per_page = res.data.per_page
            this.users    = res.data.data
          })
          .catch(() => {
            this.loading = false
          })
    },
    confirmDisable(id)
    {
      disableUser(id)
          .then(() => {
            let userToDisable        = this.users.find(user => user.id === id)
            userToDisable.is_disable = !userToDisable.is_disable
            this.toast.success("تم الحفظ بنجاح");
          })
          .catch(() => {
            this.toast.error("حدث خطأ, الرجاء المحاولة مرة أخرى");
          })
    }

  }
}
</script>

<style lang="scss">
.el-popconfirm__action {
  display: flex;
  gap: .5rem;
  flex-direction: row-reverse;
  justify-content: center;

  .el-button + .el-button {
    margin-left: 0;
  }

  button {
    min-width: 50px;
  }
}

@media (max-width: 568px) {
  .first-row > div.cell {
    padding: 0;
  }
}

</style>
